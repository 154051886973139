@charset "utf-8";

$inter: 'Inter' !default;
$recursive: 'Recursive' !default;

$maincolor: hsl(206, 98%, 74%) !default;
$accent: hsl(199, 100%, 14%) !default;
$orange: hsl(21, 89%, 52%) !default;
$gray: hsl(0, 0%, 80%) !default;
$black: hsl(0, 0%, 0%) !default;
$white: hsl(0, 0%, 100%) !default;
$text-color: hsl(0, 0%, 7%) !default;
$background-color: hsl(0, 0%, 99%) !default;

$grey-color: hsl(0, 0%, 51%) !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark: darken($grey-color, 25%) !default;

$table-text-align: left !default;

$base-font-size: 1rem !default;
$base-font-weight: 500 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 2rem !default;

$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@import
  'partials/base',
  'partials/layout'
;