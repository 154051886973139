body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}

body {
  background-color: $background-color;
  color: $text-color;
  display: flex;
  flex-direction: column;
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $inter;
  font-feature-settings: 'kern'1;
  font-kerning: normal;
  min-height: 100vh;
  text-size-adjust: 100%;
}


main {
  display: block;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

figure>img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}

ul,
ol {
  margin-left: $spacing-unit;
}

li {

  >ul,
  >ol {
    margin-bottom: 0;
  }
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $base-font-weight;
}

blockquote {
  border-left: 4px solid $grey-color-light;
  color: $grey-color;
  font-style: italic;
  letter-spacing: -1px;
  padding-left: $spacing-unit / 2;
  @include relative-font-size(1.125);

  > :last-child {
    margin-bottom: 0;
  }
}

pre,
code {
  @include relative-font-size(0.9375);
  background-color: hsl(240, 100%, 97%);
  border: 1px solid $grey-color-light;
  border-radius: 3px;
}

code {
  padding: 1px 5px;
}

pre {
  overflow-x: auto;
  padding: 8px 12px;

  >code {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
  padding-left: $spacing-unit;
  padding-right: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width: calc(#{$content-width} - (#{$spacing-unit}));
    padding-left: $spacing-unit / 2;
    padding-right: $spacing-unit / 2;
  }
}

%clearfix:after {
  clear: both;
  content: '';
  display: table;
}

.social-media-list {

  li + li {
    padding-top: 5px;
  }
}

table {
  border: 1px solid $grey-color-light;
  border-collapse: collapse;
  color: lighten($text-color, 18%);
  margin-bottom: $spacing-unit;
  text-align: $table-text-align;
  width: 100%;

  tr {

    &:nth-child(even) {
      background-color: lighten($grey-color-light, 6%);
    }
  }

  th,
  td {
    padding: ($spacing-unit / 3) ($spacing-unit / 2);
  }

  th {
    background-color: lighten($grey-color-light, 3%);
    border: 1px solid darken($grey-color-light, 4%);
    border-bottom-color: darken($grey-color-light, 12%);
  }

  td {
    border: 1px solid $grey-color-light;
  }
}