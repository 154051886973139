.orange {
  color: $orange !important;

  &.icon {
    fill: $orange;
  }
}

.blue {
  color: $maincolor !important;

  &.icon {
    fill: $maincolor;
  }
}

.site-header {
  align-items: center !important;
  border-bottom: 1px solid $grey-color-light;
  border-top: 5px solid $maincolor;
  display: inline-flex;
  justify-content: center;
  min-height: $spacing-unit * 1.865;
  position: relative;
}

.header {
  display: flex;
  flex: auto;
  flex-direction: column !important;
}

.site-footer {
  background-image: radial-gradient(
    closest-side,
    rgba(255, 255, 255, 0.94),
    rgba(255, 255, 255, 0.94),
    rgba(255, 255, 255, 1)
  ), url('/assets/images/grid.svg');
  background-repeat: no-repeat, repeat;
  background-size: auto, 75px 44px;
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.footer__link {
  color: $grey-color;
  font-weight: 700;
  text-decoration: none;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {

  .footer-col-1,
  .footer-col-2 {
    width: calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {

  .footer-col {
    float: none;
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

.page-content {
  flex: 1;
  padding: $spacing-unit 0;
}

.postlist,
.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;

  &__meta {
    color: $grey-color;
    font-size: $small-font-size;
  }

  &__link {
    color: $accent;
    display: block;
    font-weight: 700;
    text-decoration: none;
    @include relative-font-size(1.5);
  }

  &__excerpt {
    border-left: 2px solid $maincolor;
    padding: 0 0 0 1rem;
  }
}

.postheader {

  &__title {
    color: $accent;
    display: block;
    font-size: 3rem;
    font-weight: 700;
    text-decoration: none;
  }

  &__meta {
    color: $grey-color;
    font-size: $small-font-size;
  }
}

.postcontent {
  margin-bottom: $spacing-unit;

  .highlight {
    border-left: 3px solid $maincolor;
    color: hsl(232, 85%, 34%);
    display: block;
    font-size: 2rem;
    padding-left: 1rem;

  }

  p {
    margin-bottom: $spacing-unit;
  }

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
    color: #2E86AB;
    font-weight: 700;
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}


.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.logo {
  color: $black;
  font-family: $recursive;
  font-weight: 700;
  text-decoration: none;

  &.typing {
    animation: typing 2s steps(18), blink 0.5s step-end infinite alternate;
    border-right: 5px solid;
    font-size: 2em;
    font-weight: 1000;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    width: 18ch;
  }
}

.icon {
  align-self: center;
  display: inline-flex;
  height: 1rem;
  position: relative;
  top: 0.15rem;
  width: 1rem;

  svg {
    height: 100%;
    width: 100%;
  }

  &.big {
    height: 2rem;
    width: 2rem;
  }
}

.link {
  background: linear-gradient(
    to bottom,
    $maincolor 0%,
    $maincolor 100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 2px 2px;
  color: $black;
  font-weight: 700;
  text-decoration: none;
  transition: background-size 1s;

  &:hover {
    background-size: 2px 50px;
  }
}

@keyframes typing {

  from {
    width: 0;
  }
}

@keyframes blink {

  50% {
    border-color: transparent;
  }
}

@keyframes transform {

  0% {
    transform: translate(-10px, -10px);
  }

  100% {
    transform: translate(-100px, -100px);
  }
}

@keyframes opacity {

  0% {
    filter: blur(10px);
    opacity: 0.4;
  }

  100% {
    filter: blur(20px);
    opacity: 0.2;
  }
}

.box {
  height: 150px;
  transform: rotateX(55deg) rotateZ(45deg);
  width: 100px !important;

  &::before,
  &::after {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::before {
    animation: opacity 4s ease;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    background: hsl(0, 0%, 0%);
  }

  &::after {
    animation: transform 4s ease;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    background: $maincolor;
    box-shadow: 1px 1px 0 darken($maincolor, 4%),
      2px 2px 0 darken($maincolor, 4%),
      3px 3px 0 darken($maincolor, 4%),
      4px 4px 0 darken($maincolor, 4%),
      5px 5px 0 darken($maincolor, 4%);
  }
}